@import '../base/variables';
@import '../base/mixins';

.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: $btn-padding-y $btn-padding-x;
  background-color: transparent;
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  border-radius: 6px;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  letter-spacing: 1px;
  text-decoration: none;

  &:not(:disabled):not(.btn_disabled) {
    cursor: pointer;
  }

  &_primary {
    color: #fff;
    background-color: #26262b;

    &:hover:not(:disabled):not(.btn_disabled),
    &:focus:not(:disabled):not(.btn_disabled) {
      background-color: $charcoal-grey;
    }

    &:active:not(:disabled):not(.btn_disabled) {
      background-color: #2b2d32;
    }

    &:disabled,
    &.btn_disabled {
      opacity: 0.5;
    }
  }

  &_light,
  /* do not use deprecated btn_secondary, use btn_light instead */
  &_secondary {
    background-color: #fff;
    border-color: #fff;
    color: #26262b;

    &:hover:not(:disabled):not(.btn_disabled),
    &:focus:not(:disabled):not(.btn_disabled) {
      background-color: #f1f1f2;
      border-color: #fff;
    }

    &:active:not(:disabled):not(.btn_disabled) {
      background-color: #ebebec;
      border-color: #ebebec;
    }

    &:disabled,
    &.btn_disabled {
      opacity: 0.5;
    }
  }

  &_outline-primary {
    color: #26262b;
    border-color: #26262b;

    &:hover:not(:disabled):not(.btn_disabled),
    &:focus:not(:disabled):not(.btn_disabled) {
      background-color: #26262b;
      color: #fff;
    }

    &:active:not(:disabled):not(.btn_disabled) {
      background-color: #2b2d32;
    }

    &:disabled,
    &.btn_disabled {
      opacity: 0.5;
    }

    &.btn_with-icon_active {
      // stylelint-disable selector-max-type
      > img {
        filter: invert(0.85);
      }

      &:focus:not(:disabled):not(.btn_disabled) {
        background-color: $lightish-green;
        border-color: $lightish-green;
      }

      &:hover > img {
        filter: invert(0);
      }

      &:active:not(:disabled):not(.btn_disabled) {
        background-color: #2b2d32;
        border-color: #2b2d32;
      }
    }

    &.btn_with-icon_static {
      background-color: $lightish-green;
      border-color: $lightish-green;
      color: $white;

      &:focus:not(:disabled):not(.btn_disabled),
      &:hover:not(:disabled):not(.btn_disabled) {
        background-color: $lightish-green;
        border-color: $lightish-green;
      }

      &:active:not(:disabled):not(.btn_disabled) {
        background-color: #2b2d32;
        border-color: #2b2d32;
      }
    }
  }

  &_outline-light {
    border-color: #fff;
    color: #fff;

    &:hover:not(:disabled):not(.btn_disabled),
    &:focus:not(:disabled):not(.btn_disabled) {
      background-color: #f1f1f2;
      color: #26262b;
    }

    &:active:not(:disabled):not(.btn_disabled) {
      background-color: #fff;
    }

    &:disabled,
    &.btn_disabled {
      opacity: 0.5;
    }

    &.btn_with-icon_active {
      &:focus:not(:disabled):not(.btn_disabled) {
        background-color: $lightish-green;
        border-color: $lightish-green;
        color: $white;
      }

      &:hover > img {
        filter: invert(0.85);
      }
      // stylelint-enable selector-max-type

      &:active:not(:disabled):not(.btn_disabled) {
        background-color: #2b2d32;
        border-color: #2b2d32;
        color: $white;
      }
    }

    &.btn_with-icon_static {
      background-color: $lightish-green;
      border-color: $lightish-green;

      &:focus:not(:disabled):not(.btn_disabled),
      &:hover:not(:disabled):not(.btn_disabled) {
        background-color: $lightish-green;
        border-color: $lightish-green;
        color: $white;
      }

      &:active:not(:disabled):not(.btn_disabled) {
        background-color: #2b2d32;
        border-color: #2b2d32;
        color: $white;
      }
    }
  }

  &_outline-secondary {
    color: $dark-grey;
    border-color: $light-silver;

    &:hover,
    &:focus {
      color: $dark-grey;
      background-color: transparent;
      border-color: darken($light-silver, 15%);
      // Reset link decoration
      text-decoration: none;
    }

    &.disabled,
    &:disabled {
      color: $dark-grey;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $dark-grey;
      background-color: transparent;
      border-color: darken($light-silver, 75%);
    }
  }

  &_google {
    background-color: $red-google;
    color: $white;

    &:hover,
    &:focus {
      background-color: $red-google-lighter;
    }

    &:active {
      background-color: $red-google-darker;
    }
  }

  &_facebook {
    background-color: $blue-facebook;
    color: $white;

    &:hover,
    &:focus {
      background-color: $blue-facebook-lighter;
    }

    &:active {
      background-color: $blue-facebook-darker;
    }
  }

  &_link {
    color: $dusty-orange;
    font-family: $font-family-base;
    font-size: $font-size-text-md;
    font-weight: $font-weight-medium;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    display: inline;
  }

  &_sm {
    padding: 5px 22px;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &_md {
    padding: 10px 26px;
    font-size: $font-size-text-md;
    line-height: $line-height-medium;
  }

  &_block {
    display: block;
    width: 100%;
  }

  &_no-spacing {
    padding-left: 0;
    padding-right: 0;
  }

  &_block-mobile {
    @include screen-to(xs) {
      display: block;
      width: 100%;
    }
  }

  &_play {
    padding: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.5);
    background: $white url(image-url('/components/buttons/button-play.svg')) no-repeat 52% 50%;
    background-size: 18%;
    transition: transform 0.15s ease-in-out;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }

    @include screen-from(sm) {
      &:not(&_sm) {
        width: 74px;
        height: 74px;
      }
    }
  }

  &__input-close {
    z-index: 100;
    position: absolute;
    top: 8px;
    right: 16px;
    padding: 0;
    width: 32px;
    height: 32px;
    background: url(image-url('/components/buttons/button-close.svg')) no-repeat center;
    background-size: 32px;
    opacity: 0.95;
    visibility: visible;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &:active {
      opacity: 0.95;
    }

    @include screen-from(md) {
      top: 14px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;

      &:hover,
      &:focus {
        opacity: 0;
      }
    }
  }
}

.btn-link {
  font-size: 20px;
  letter-spacing: 1.6px;

  &_dark {
    color: $charcoal-grey-4;
    font-weight: $font-weight-bold;

    &.btn-link_with-arrow {
      background-image: url(image-url('/components/buttons/arrow-right-dark.svg'));
    }
  }

  &_light {
    color: $white;
    font-weight: $font-weight-medium;

    &.btn-link_with-arrow {
      background-image: url(image-url('/components/buttons/arrow-right-light.svg'));

      @include screen-to(sm) {
        text-align: left;
        text-wrap: auto;
      }
    }
  }

  &_with-arrow {
    background-position: left $btn-padding-x center;
    background-repeat: no-repeat;
    transition: background-position 0.1s ease-in-out;
    padding-left: 38px + $btn-padding-x;

    &:hover {
      background-position: left ($btn-padding-x + 3) center;
    }

    &.btn_no-spacing {
      padding-left: 38px;
      background-position: left center;

      &:hover {
        background-position: left 3px center;
      }
    }
  }
}

.btn-group {
  $buttons-spacing: 20px;
  display: flex;
  flex-flow: row wrap;
  margin-left: -$buttons-spacing * 0.5;
  margin-right: -$buttons-spacing * 0.5;

  &__item {
    margin-left: $buttons-spacing * 0.5;
    margin-right: $buttons-spacing * 0.5;
    flex: 0 0 auto;
  }
}
