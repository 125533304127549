@import '../../base/variables';
@import '../../base/mixins';

.referral-balloon {
  position: absolute;
  padding: 2px 20px 16px;
  top: 46px;
  background-color: $white;
  box-shadow: 0 5px 20px 0 rgba(#000, 0.25);
  border-radius: 6px;

  &::before {
    content: '';
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    left: 48%;
    top: -7px;
    transform: rotate(135deg);
    background-color: #fff;
    border: 1px solid;
    border-color: transparent transparent #e5e5e5 #e5e5e5;

    @include screen-from(sm) {
      left: 84%;
    }
  }

  &__container {
    width: 80vw;
    position: absolute;
    display: none;
    height: 0;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);

    @include screen-from(sm) {
      width: 380px;
      transform: unset;
      left: unset;
    }
  }

  &__title {
    font-size: $font-size-text-md;
    font-weight: $font-weight-bold;
    margin-bottom: 8px;
  }

  &__description {
    margin-bottom: 10px;
  }

  &__link {
    font-weight: $font-weight-medium;
  }
}
