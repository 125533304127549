@import '../../base/mixins';
@import '../../base/variables';

.cookie-banner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: $dark-grey;
  border-radius: 10px;
  border: solid 1px rgba($white, 0.25);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  color: $white;
  width: calc(100% - 24px);
  max-width: 500px;

  @include screen-from(sm) {
    align-items: center;
  }

  @include screen-from(lg) {
    left: 20px;
    transform: none;
  }

  &__text {
    font-size: 12px;
    line-height: 1.67;
    margin-right: 12px;
  }
}
