@import '../../base/variables';
@import '../../base/mixins';

.signup-modal {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px;
  }

  &__logo {
    position: absolute;
    top: 24px;
    left: 16px;

    @include screen-from(md) {
      top: 60px;
      left: 124px;
    }

    @include screen-from(lg) {
      top: 64px;
      left: 94px;
    }
  }
}
