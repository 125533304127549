.layout-backdroped {
  background-color: $pale;
  min-height: 100%;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content {
    position: relative;
    background-color: #fefefe;
    height: 100%;
    border: 1px solid #dedee0;
    border-radius: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
