@import '~bootstrap/scss/mixins/hover';
@import '../base/mixins';
@import '../base/variables';
@import '../components/logo';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1280px
);

@mixin navigationSticky {
  transform: translateY(0);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
  background: $white;
}

@mixin navigationStatic {
  transform: translateY(-100%);
  box-shadow: none;
  background: $white;
}

@keyframes navigationSlideDown {
  from {
    @include navigationStatic;
  }

  to {
    @include navigationSticky;
  }
}

@keyframes navigationSlideUp {
  from {
    @include navigationSticky;
  }

  to {
    @include navigationStatic;
  }
}

@keyframes navigationMenuClose {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes navigationMenuOpen {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes navigationOverlayHide {
  from {
    visibility: visible;
    background: rgba(38, 38, 43, 0.4);
  }

  to {
    visibility: hidden;
    background: none;
  }
}

@keyframes navigationOverlayShow {
  from {
    visibility: hidden;
    background: none;
  }

  to {
    visibility: visible;
    background: rgba(38, 38, 43, 0.4);
  }
}

.site-navigation {
  &__lang-switcher {
    display: none;

    @include screen-from(xxl) {
      display: block;
    }
  }

  &__bar {
    position: absolute;
    z-index: 150;
    width: 100%;
    padding-top: $navigation-padding-y;
    padding-bottom: $navigation-padding-y;

    &_sticky {
      background: $white;
      transform: translateY(-100%);
      animation: navigationSlideDown 0.3s ease-in-out both;

      .logo__caption, .logo__by-macpaw {
        @include screen-to('xs') {
          display: none;
        }
      }

      .site-navigation__signup-button_lite {
        margin: 0 auto;

        @include screen-from(sm) {
          margin: 0;
        }
      }

      .site-navigation__link-lite {
        display: none;

        @include screen-from(sm) {
          display: block;
        }
      }
    }

    &_static {
      animation: navigationSlideUp 0.3s ease-in-out;
    }

    &_white {
      .site-navigation__link {
        @include screen-from(xxl) {
          color: $white;
          -webkit-font-smoothing: antialiased;
        }
      }

      .site-navigation__link-lite {
        color: $white;
        -webkit-font-smoothing: antialiased;
      }

      .site-navigation__open-button {
        color: $white;
      }

      .site-navigation__item-divider {
        @include screen-from(xxl) {
          background: $white;
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: none;
    visibility: hidden;

    @include screen-from(xxl) {
      position: initial;
      height: initial;
      margin: 0 0 0 auto;
      background: none;
    }

    &_shown {
      animation: navigationOverlayShow 0.3s ease-in-out both;

      @include screen-from(xxl) {
        animation: none;
        background: none;
      }
    }

    &_hidden {
      animation: navigationOverlayHide 0.3s ease-in-out;
    }
  }

  &__content {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    max-width: $layout-navigation-width-max;
    margin: 0 auto;
    padding-left: $layout-content-padding-x * 0.5;
    padding-right: $layout-content-padding-x * 0.5;

    @include screen-from(xxl) {
      padding-left: $layout-content-padding-x;
      padding-right: $layout-content-padding-x;
    }
  }

  &__signup-button {
    display: none;

    @include screen-from(sm) {
      display: block;
      margin-left: auto;
      margin-right: 20px;
    }

    @include screen-from(xxl) {
      display: none;
    }

    &_lite {
      margin-right: auto;
      margin-left: auto;
      position: relative;
      right: 10px;

      @include screen-from(sm) {
        margin: 0;
        position: static;
      }
    }
  }

  &__open-button {
    $button-height: 20px;
    border: none;
    cursor: pointer;
    height: $button-height;
    // add some margin to take the same place vertically as menu list height
    margin: ($navigation-height - $navigation-padding-y * 2 - $button-height) * 0.5 0;
    padding: 0;
    background: none;
    color: $dark-grey;
    fill: currentcolor;
    transition: color 0.3s ease-in-out;

    @include screen-from(xxl) {
      display: none;
    }
  }

  &__bar-cta {
    display: none;

    @include screen-from(xxl) {
      display: inline-block;
    }
  }

  &__close-button {
    position: absolute;
    right: 20px;
    top: 26px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    color: $dark-grey;
    fill: currentcolor;
    transition: color 0.3s ease-in-out;

    @include screen-from(xxl) {
      display: none;
    }
  }

  &__menu-slider {
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    transform: translateX(100%);
    background: $white;

    @include screen-from(sm) {
      width: initial;
    }

    @include screen-from(xxl) {
      height: initial;
      position: initial;
      transform: translateX(0);
      background: none;
    }

    &_open {
      animation: navigationMenuOpen 0.3s ease-in-out both;
    }

    &_closed {
      animation: navigationMenuClose 0.3s ease-in-out;
    }
  }

  &__menu-list-container {
    overflow: auto;
    height: 100%;
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    background: url(image-url('/components/navigation/logo-symbol-small.svg')) no-repeat left 20px top 21px;
    background-size: 19px 30px;
    padding: 82px 20px 0;

    @include screen-from(sm) {
      background: url(image-url('/components/navigation/logo-symbol-small.svg')) no-repeat left 90px top 130px;
      background-size: 38px 60px;
      padding: 245px 64px 0;
    }

    @include screen-from(xxl) {
      position: initial;
      flex-direction: row;
      align-items: center;
      flex-shrink: 0;
      height: initial;
      padding: $navigation-list-padding-y 15px;

      &_small {
        padding-left: 12px;
        padding-right: 12px;

        .site-navigation__menu-list-item {
          margin: 0 12px;
        }

        .site-navigation__item-divider {
          margin: 0 12px;
        }

        .site-navigation__link {
          font-size: $font-size-text-xs;
        }
      }
    }
  }

  &__menu-list-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 280px;

    @include screen-from(xxl) {
      min-width: 306px;
    }

    @include screen-from(xxl) {
      min-width: initial;
      margin: 0 15px;
    }
  }

  &__menu-list-item_xxl-none {
    @include screen-from(xxl) {
      display: none;
    }
  }

  &__item-divider {
    @include screen-from(xxl) {
      margin: 0 15px;
      height: 20px;
      width: 1px;
      background: $heather;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: $navigation-link-padding-y $navigation-link-padding-x;
    margin: 4px 0;
    color: $dark-grey;
    font-size: $navigation-font-size;
    line-height: $navigation-line-height;
    font-weight: $font-weight-bold;
    letter-spacing: 0.9px;
    text-decoration: none;
    transition: box-shadow 0.15s ease-in-out,
    color 0.3s ease-in-out;

    @include hover-focus-active {
      text-decoration: none;
    }

    &_active {
      border-radius: 6px;
      background: $white-smoke;
      width: 100%;
    }

    @include screen-from(sm) {
      padding: $navigation-link-padding-y $navigation-link-padding-x-tablet;
      font-size: $navigation-font-size-tablet;
    }

    @include screen-from(xxl) {
      padding: $navigation-link-padding-y $navigation-link-padding-x-desktop;
      margin: 0;
      border-radius: 0;
      font-size: $navigation-font-size-desktop;
      font-weight: $font-weight-medium;

      @include hover-focus-active {
        box-shadow: inset 0 -2px 0 0 currentcolor;
      }

      &_active {
        box-shadow: inset 0 -2px 0 0 currentcolor;
        background: none;
      }
    }
  }

  &__link-lite {
    display: block;
    margin-left: auto;

    font-size: $navigation-font-size-desktop;
    font-weight: $font-weight-medium;
    color: $dark-grey;
    text-decoration: none;
    transition: box-shadow 0.15s ease-in-out;

    @include screen-from(sm) {
      margin-right: 16px;
    }

    @include hover-focus-active {
      box-shadow: inset 0 -2px 0 0 currentcolor;
    }
  }

  &__cta-promo-link {
    margin-left: auto;
  }

  &__beta-label {
    display: inline-flex;
    padding: 0 8px;
    margin-left: 8px;
    font-weight: $font-weight-medium;
    font-size: $font-size-text-xs;
    color: $white;
    background: #e6842e;
    border-radius: 360px;
  }
}
