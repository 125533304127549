@import '../../base/mixins';
@import '../../base/variables';

.badges-banner {
  display: flex;
  flex-direction: column;
  background-color: $dark-grey;
  border-radius: 10px;
  padding: 40px 20px 36px;
  color: $text-color-inverse;

  @include screen-from(md) {
    border-radius: 16px;
    flex-direction: row;
    padding: 42px 26px 23px;
  }

  @include screen-from(xl) {
    padding: 60px 30px 44px;
  }

  &__message-container {
    @include screen-from(md) {
      flex: 1;
    }

    @include screen-from(xl) {
      flex: 1.43;
    }
  }

  &__message {
    letter-spacing: -0.1px;
    line-height: $line-height-app-title;

    @include screen-from(md) {
      line-height: 1.57;
      margin-right: 50px;
    }

    @include screen-from(xl) {
      margin-right: 0;
    }
  }

  &__ratings-container {
    margin-top: 12px;
    margin-bottom: 18px;
  }

  &__ratings-stars {
    display: inline-flex;
    vertical-align: sub;
    gap: 6px;
    margin-right: 4px;
  }

  &__ratings-score {
    display: inline-block;
    margin: 0 4px;
    vertical-align: middle;
  }

  &__ratings-quantity {
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-text-md;
    color: $text-color-gray;
  }

  &__badges-container {
    display: flex;
    justify-content: space-between;

    @include screen-from(md) {
      flex: 1;
      align-items: flex-start;
    }
  }

  &__badge {
    max-width: 86px;
    max-height: 112px;

    @include screen-from(md) {
      max-width: 104px;
      max-height: 117px;

      &_vertical-alignment {
        max-height: 135px;
        margin-top: -10px;
      }
    }

    @include screen-from(xl) {
      max-width: 142px;
      max-height: 160px;

      &_vertical-alignment {
        max-width: 140px;
        max-height: 182px;
      }
    }
  }
}
