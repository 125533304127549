@import '../../base/variables';
@import '../../base/mixins';

.page-404 {
  flex: 1;
  background: $charcoal-grey-2;

  &-store {
    background: $charcoal-grey-6;
  }

  &__wrap {
    background-color: $light-gray;
    padding-top: $navigation-height + 48px + 40px;

    @include screen-from(md) {
      padding-top: $navigation-height + 48px + 60px;
    }

    @include screen-from(lg) {
      padding-top: $navigation-height + 68px + 100px;
    }
  }

  &__background {
    background: linear-gradient($light-gray 50%, $charcoal-grey-2 50%);

    &-store {
      background: linear-gradient($light-gray 50%, $charcoal-grey-6 50%);
    }
  }

  &__content {
    overflow: visible;
  }

  &__container {
    padding-top: 0;
    padding-bottom: 8px;
    margin-bottom: 20px;

    @include screen-from(md) {
      padding-bottom: 20px;
    }

    @include screen-from(lg) {
      padding-bottom: 0;
    }
  }

  &__window {
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translateY(-16.6%);
  }

  &__illustration {
    width: 55%;
    min-width: 260px;
    max-width: 700px;
    height: auto;
  }

  &__text {
    margin-bottom: 40px;
    margin-top: 24px;

    @include screen-from(lg) {
      margin-bottom: 48px;
    }
  }
}
