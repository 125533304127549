/* stylelint-disable selector-max-type */

@import 'variables';

*,
::after,
::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  min-width: 320px;
  background: #fff;
  height: 100%;
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}
