@import '../../base/variables';
@import '../../base/mixins';

.store-search {
  background-color: transparent;
  width: 100%;
  border: 2px solid rgba(38, 38, 43, 0.1);
  color: $dark-grey;
  border-radius: 6px;
  margin-bottom: 28px;
  transition: all 0.3s ease-in-out;

  @include screen-from(xl) {
    margin: 0;
  }

  &_dark {
    border-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  &_active {
    border-color: rgba(38, 38, 43, 0.1);
    background-color: $white;
    color: $dark-grey;
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 0 10px 0 16px;
    gap: 12px;
    cursor: text;

    @include screen-from(xl) {
      padding: 0 12px;
    }
  }

  &__icon {
    pointer-events: none;

    @include screen-from(xl) {
      width: 16px;
      height: 16px;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-text-lg;
    line-height: 28px;
    padding: 10px 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $heather;
    }

    @include screen-from(md) {
      padding: 14px 0;
    }

    @include screen-from(xl) {
      padding: 8px 0;
      font-size: $font-size-text-md;
      line-height: 1.25;
    }
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    user-select: none;
    padding: 6px;
    color: inherit;
  }

  &__close-icon {
    @include screen-from(xl) {
      width: 16px;
      height: 16px;
    }
  }
}
