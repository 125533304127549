@import '../../base/mixins';
@import '../../base/variables';

.ios-waitlist-banner {
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 74px;
  width: 100%;
  z-index: 6;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 28px;
    padding-bottom: 28px;
    background-color: #3e3e46;

    &_beta {
      background-color: #fadbe6;
    }

    @include screen-from(md) {
      height: $header-banner-height-md;
      padding: 0 50px;
      flex-direction: row;
      justify-content: center;
      gap: 16px;
    }

    @include screen-from(xl) {
      align-items: center;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @include screen-from(md) {
      flex-direction: row;
      gap: 32px;
    }

    @include screen-from(xl) {
      gap: 40px;
    }
  }

  &__title {
    text-align: center;
    width: 300px;
    font-size: $font-size-text-lg;
    font-weight: $font-weight-bold;
    line-height: 28px;

    @include screen-from(md) {
      text-align: left;
      width: 100%;
    }
  }

  &__cta {
    max-width: 100%;
    padding: 6px 16px;
  }

  &__image {
    margin-bottom: 30px;

    &_beta {
      margin-bottom: 20px;
    }
  }
}
