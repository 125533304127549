@import './utlis/text';

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.img {
  max-width: 100%;
}
