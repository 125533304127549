@import '../base/variables';

.tab-radio-button {
  position: relative;

  &__input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0;
  }

  &__wrapper {
    display: block;
    position: relative;
    padding: 7px 25px 5px 46px;
    border-radius: 6px;
    color: $charcoal-grey-4;
    font-size: 18px;
    font-weight: $font-weight-medium;
    text-decoration: none;
    line-height: 1.78;
    background-color: rgba($white, 0.2);
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;

    .tab-radio-button__input:focus + .tab-radio-button__label & {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }

    .tab-radio-button__input:checked + .tab-radio-button__label & {
      background-color: rgba($white, 0.9);
    }

    .tab-radio-button__input:hover + .tab-radio-button__label & {
      background-color: $white;
    }

    .tab-radio-button__input:active + .tab-radio-button__label & {
      background-color: $pale-gray;
    }

    .tab-radio-button_inverse & {
      color: $white;
    }

    .tab-radio-button_inverse .tab-radio-button__input:hover + .tab-radio-button__label &,
    .tab-radio-button_inverse .tab-radio-button__input:focus + .tab-radio-button__label &,
    .tab-radio-button_inverse .tab-radio-button__input:checked + .tab-radio-button__label &,
    .tab-radio-button_inverse .tab-radio-button__input:active + .tab-radio-button__label & {
      color: $charcoal-grey-4;
    }
  }

  &__label-bullet {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    fill: $charcoal-grey-4;
    transition: fill 0.3s;

    .tab-radio-button_inverse & {
      fill: $white;
    }

    .tab-radio-button_inverse .tab-radio-button__input:checked + .tab-radio-button__label &,
    .tab-radio-button_inverse .tab-radio-button__input:hover + .tab-radio-button__label & {
      fill: $charcoal-grey-4;
    }
  }
}
