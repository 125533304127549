/* stylelint-disable selector-max-type */
@import 'variables';
@import 'mixins';

@mixin text-body {
  font-family: $font-family-base;
  font-size: $font-size-text-lg;
  color: $text-color-base;
  line-height: $line-height-base;
}

body {
  @include text-body;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: $font-weight-heading;
  margin-bottom: $headings-margin-bottom;
  color: $headings-color;
}

h1,
.h1 {
  font-family: $font-family-base;
  font-size: $font-size-h1-mobile;
  font-weight: $font-weight-bold;
  line-height: 1.37;
  letter-spacing: 1.15px;
  -webkit-font-smoothing: antialiased;

  @include screen-from(lg) {
    font-size: $font-size-h1;
    line-height: 1.3;
    letter-spacing: 1.3px;
  }
}

h1.h1_hero,
.h1_hero {
  font-family: $font-family-base;
  font-size: $font-size-h1-hero-mobile;
  font-weight: $font-weight-hero-heading;
  line-height: 1.06;
  letter-spacing: 1px;

  @include screen-from(md) {
    font-size: $font-size-h1-hero-tablet;
  }

  @include screen-from(lg) {
    font-size: $font-size-h1-hero;
  }
}

h1.h1_hero-medium,
.h1_hero-medium {
  font-family: $font-family-base;
  font-size: $font-size-h1-hero-mobile;
  font-weight: $font-weight-hero-heading;
  line-height: 1.06;
  letter-spacing: 1px;

  @include screen-from(md) {
    font-size: $font-size-h1-hero-tablet;
  }
}

h1.h1_hero-small,
.h1_hero-small {
  font-family: $font-family-base;
  font-size: $font-size-h1-hero-mobile;
  font-weight: $font-weight-hero-heading;
  line-height: 1.06;
  letter-spacing: 1px;

  @include screen-from(lg) {
    font-size: $font-size-h1-hero-tablet;
  }
}

h2,
.h2 {
  font-size: $font-size-h2-mobile;
  line-height: 1.53;
  letter-spacing: 1.2px;

  @include screen-from(lg) {
    font-size: $font-size-h2;
    line-height: 1.5;
  }
}

h3,
.h3 {
  font-size: $font-size-h3-mobile;
  line-height: 1.334;
  letter-spacing: 1.1px;

  @include screen-from(lg) {
    font-size: $font-size-h3;
    line-height: 1.44;
    letter-spacing: 1px;
  }
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: 1.54;
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

h5.h5_small,
.h5_small {
  font-size: $font-size-text-lg;
  font-weight: $font-weight-heading;
  line-height: 1.4;
  letter-spacing: 0.5px;

  @include screen-from(lg) {
    font-size: $font-size-h5;
  }
}

h6,
.h6 {
  font-size: $font-size-h6;
  line-height: 1.5;
}

small,
.small {
  font-size: 15px;
  line-height: normal;
}

.lead {
  font-size: 20px;
  line-height: 1.6;
}

b,
strong {
  font-weight: $font-weight-bold;
}

.text_body {
  @include text-body;
  margin: 0;
  padding: 0;
  font-weight: $font-weight-regular;
  letter-spacing: normal;
}

.text_xs {
  font-size: $font-size-text-xs;
  line-height: 1.67;
}

.text_sm {
  font-size: $font-size-text-sm;
  line-height: 1.43;
}

.text_md {
  font-size: $font-size-text-md;
  line-height: 1.5;
}

.text_lg {
  font-size: $font-size-text-lg;
  line-height: 1.78;
}
