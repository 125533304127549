@import '../variables';

.text {
  &-dark {
    color: $text-color-dark;
  }

  &-gray {
    color: $text-color-gray;
  }

  &-light {
    color: $text-color-light;
  }

  &-inverse {
    color: $text-color-inverse;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-justify {
    text-align: justify;
  }

  &-left {
    text-align: left;
  }
}

.fw-medium {
  font-weight: $font-weight-medium;
}
