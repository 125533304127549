@import '../base/variables';

.ul {
  list-style: none;
  padding: 0 0 0 33px;
  margin: 20px 0;
}

.li {
  position: relative;
  margin: 14px 0;

  &::before {
    content: '';
    position: absolute;
    top: ($font-size-base * $line-height-base) * 0.5;
    left: -28px;
    height: 11px;
    width: 11px;
    margin-top: -7px;
    border-radius: 2px;
    background-color: #3a3844;
    transform: rotate(45deg);
  }
}
