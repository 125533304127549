@import '../../base/variables';
@import '../../base/mixins';
@import '../../components/buttons';

.signup-form {
  opacity: 1;
  transition: opacity 0.15s;
  padding-top: 28px;

  &__content-wrapper {
    max-width: 480px;
    margin: 0 auto;

    @include screen-from(lg) {
      max-width: 520px;
    }

    &:focus-within .signup-form__password-requirements {
      visibility: hidden;

      @include screen-from(lg) {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &-step_faded {
    opacity: 0;
  }

  &__separator {
    font-size: 15px;
    margin-bottom: 16px;
    text-align: center;
    text-transform: lowercase;
  }

  &__footer {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    color: $heather;
    font-size: 15px;
    line-height: normal;
  }

  &__prev-step-btn {
    position: relative;
    display: inline-block;
    background: transparent;
    border: none;
    font-family: $font-family-base;
    font-size: $font-size-text-md;
    font-weight: $font-weight-medium;
    color: $heather;
    padding: 4px 4px 4px 16px;
    margin-bottom: 16px;
    cursor: pointer;

    &::before {
      display: block;
      position: absolute;
      content: '';
      top: 8px;
      left: 0;
      width: 6px;
      height: 10px;
      background: url(image-url('/components/signup-form/arrow-left.svg')) center no-repeat;
    }
  }

  &__form-group-password {
    position: relative;
  }

  &__input-password {
    padding-right: 80px;
  }

  &__password-requirements {
    opacity: 0;
    position: absolute;
    padding: 16px;
    padding-left: 44px;
    margin: 0;
    width: 300px;
    top: 0;
    left: 105%;
    border-radius: 6px;
    background: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
    list-style: none;

    transition: opacity 0.5s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: -6px;
      width: 20px;
      height: 20px;
      background-color: $white;
      transform: rotate(45deg);
      border-radius: 6px 0;
    }

    &_visible {
      visibility: visible;
      opacity: 1;
    }

    &__item {
      font-size: $font-size-text-sm;
      margin-bottom: 12px;
      position: relative;
      color: $dark-grey;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: -26px;
        background-image: url(image-url('/components/signup-form/signup-form-password-gray.svg'));
      }

      &_valid {
        color: $lightish-green;

        &::before {
          background-image: url(image-url('/components/signup-form/signup-form-password-green.svg'));
        }
      }

      &_invalid {
        color: $dark-grey;

        &::before {
          background-image: url(image-url('/components/signup-form/signup-form-password-red.svg'));
        }
      }
    }
  }

  &__toggle-signup-password {
    display: block;
    background: transparent;
    color: $dusty-orange;
    font-family: $font-family-base;
    font-size: $font-size-text-md;
    font-weight: $font-weight-medium;
    cursor: pointer;
    padding: 9px 16px;
    border: 2px solid $heather;
    border-radius: 0 6px 6px 0;
    border-left: none;
  }

  &__user-email-text {
    font-size: $font-size-text-md;
    line-height: 1.5;
    margin-bottom: 28px;
  }

  &__retype-email-button {
    color: $dusty-orange;
    font-family: $font-family-base;
    font-size: $font-size-text-md;
    font-weight: $font-weight-medium;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    display: inline;
  }

  &__email-placeholder {
    font-weight: $font-weight-medium;
  }

  &__primary-button {
    margin-top: 24px;
  }

  & &__form-group-checkbox {
    flex-wrap: wrap;

    .form-check__form-control {
      flex-shrink: 0;
    }

    .form-check__label {
      flex-basis: 85%;
      flex-grow: 1;
    }
  }

  &__social-buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }

  &__social-button-icon {
    pointer-events: none;
  }

  // to reassign default classes of button as we have icon inside
  &__social-button.btn {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0;
  }

  &_pending-status {
    .signup-form__form-group {
      opacity: 0.5;
    }

    .signup-form__form-text {
      opacity: 0.5;
    }

    .signup-form__social-buttons {
      opacity: 0.5;
    }
  }
}
