@import '../base/mixins';
@import '../base/variables';

.social {
  white-space: nowrap;
  padding: 0;
  margin: 14px 0 0;
  font-size: 0;

  &_colored {
    .social__link {
      opacity: 0.9;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 1;
      }
    }

    .social__icon_youtube {
      color: #eb0c19;
    }

    .social__icon_instagram {
      color: #d41792;
    }

    .social__icon_fb {
      color: #3a5792;
    }

    .social__icon_x {
      color: #26262b;
    }

    .social__icon_discord {
      color: #5865f2;
    }
  }

  &__icon {
    color: $white;
  }

  @include screen-from(md) {
    text-align: right;
    margin: 0;
  }

  &__item {
    display: inline-block;
    margin-left: 12px;

    &:first-child {
      margin: 0;
    }
  }

  &__link {
    transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.1s step-end;

    opacity: 0.45;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }
}
