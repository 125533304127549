@import '../base/variables';
@import '../base/mixins';
@import '../components/store-logo/store-logo';

.store-navigation {
  min-height: 48px;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  color: $dark-grey;

  &_sticky {
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    animation: storeNavigationSlideDown 0.3s ease-in-out both;
    background-color: $white;

    .store-logo__text, .store-logo__by-macpaw {
      @include screen-to(xs) {
        display: none;
      }
    }

    .store-navigation__account-button {
      @include screen-to(xs) {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &_static {
    animation: storeNavigationSlideUp 0.3s ease-in-out;
  }

  &__main {
    min-height: 48px;
    width: 100%;
    position: absolute;
    z-index: 100;

    @include screen-from(xl) {
      height: $header-banner-height-md;
    }
  }

  @keyframes storeNavigationSlideDown {
    from {
      transform: translateY(-100%);
      box-shadow: none;
      background: $white;
    }

    to {
      transform: translateY(0);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
      background: $white;
    }
  }

  @keyframes storeNavigationSlideUp {
    from {
      transform: translateY(100%);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
      background: $white;
    }

    to {
      transform: translateY(-100%);
      box-shadow: none;
      background: $white;
    }
  }

  @include screen-from(xl) {
    height: $header-banner-height-md;
    padding: 0 40px;
  }

  &_dark {
    color: $white;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 16px 10px 16px 20px;

    @include screen-from(xl) {
      max-width: $layout-navigation-width-max;
      padding: 18px 0;
      gap: 72px;
    }

    &-search {
      display: none;

      @include screen-from(xl) {
        display: block;
        flex: 1;
      }
    }

    &-menu {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      margin-left: 5px;
      gap: 8px;

      @include screen-from(md) {
        margin-left: 0;
        gap: 12px;
      }

      @include screen-from(xl) {
        gap: 28px;
        justify-content: flex-start;
        width: auto;
      }
    }
  }

  &__menu-link {
    display: none;
    align-items: center;
    gap: 8px;
    font-size: $font-size-text-sm;
    letter-spacing: 0.9px;
    color: inherit;
    text-decoration: none;
    order: 0;

    @include screen-from(xl) {
      display: inline-flex;
    }
  }

  &__menu-separator {
    display: none;
    background-color: $dark-grey;
    transition: background-color 0.3s ease-in-out;
    height: $font-size-h5;
    width: 1px;

    @include screen-from(xl) {
      display: block;
    }

    &_dark {
      background-color: $white;
    }
  }

  &__account-button {
    display: none;
    padding: 6px 16px;
    border-radius: 6px;
    line-height: 20px;
    color: $white;
    background-color: $dark-grey;
    letter-spacing: 1px;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-text-sm;
    border: 0;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    @include screen-from(md) {
      margin: 0;
    }

    @include screen-from(sm) {
      display: block;
    }

    &_dark {
      color: $dark-grey;
      background-color: $white;
    }
  }

  &__burger {
    &-container {
      position: relative;
      display: none;
      user-select: none;
      border: 0;
      background-color: transparent;

      @include screen-from(xl) {
        display: flex;
      }

      &:hover {
        box-shadow: none;
      }

      &:hover .store-navigation__burger-background {
        display: block;
      }

      &:hover .store-navigation__burger-menu {
        display: flex;
        animation: menuSlide 0.3s ease-in-out;
      }

      &:hover .store-navigation__burger-menu::before {
        display: block;
        animation: tailSlide 0.3s ease-in-out;
      }
    }

    &-background {
      display: none;
      position: absolute;
      width: 100%;
      height: 44px;
      left: 0;
      background-color: transparent;
    }

    &-content {
      display: flex;
      position: relative;
      gap: 8px;
      z-index: 1;
      cursor: pointer;
    }

    &-icon {
      vertical-align: sub;

      &-container {
        font-family: $font-family-base;
        display: flex;
        justify-content: center;
      }
    }

    &-text {
      display: none;
      font-family: $font-family-base;

      @include screen-from(xl) {
        display: block;
      }
    }
  }

  &__burger-menu {
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: center;
    color: $dark-grey;
    background-color: $white;
    z-index: 100;
    animation: menuSlideDown 0.3s ease-in-out;
    padding: 12px 8px;
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(38, 38, 43, 0.3);
    min-width: 184px;
    transform: translateY(30px);
    cursor: default;

    &_active {
      display: flex;
      animation: menuSlide 0.3s ease-in-out;

      &::before {
        display: block;
        animation: tailSlide 0.3s ease-in-out;
      }
    }

    &::before {
      position: absolute;
      transform: translateY(-20px);
      content: '';
      display: none;
      width: 20px;
      height: 13px;
      clip-path: polygon(0% 100%, 35% 25%, 38% 20%, 40% 15%, 50% 10%, 60% 15%, 62% 20%, 65% 25%, 100% 100%);
      background-color: $white;
      animation: tailSlideDown 0.3s ease-in-out;
    }

    &-link {
      width: 100%;
      display: block;
      padding: 6px 12px;
      font-size: $font-size-text-sm;
      line-height: 20px;
      font-weight: $font-weight-medium;
      border-radius: 6px;
      text-decoration: none;
      color: inherit;
      text-align: left;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: $smoke-gray;
      }
    }

    @keyframes menuSlide {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes menuSlideDown {
      from {
        display: flex;
        opacity: 1;
      }

      to {
        display: none;
        opacity: 0;
      }
    }

    @keyframes tailSlide {
      from {
        transform: translateY(0);
      }

      to {
        transform: translateY(-20px);
      }
    }

    @keyframes tailSlideDown {
      from {
        display: block;
        transform: translateY(-20px);
      }

      to {
        display: none;
        transform: translateY(0);
      }
    }
  }
}
