@import '../base/variables';
@import '../base/mixins';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__navigation {
    width: 100%;
  }

  &__main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    &_with-header-banner {
      .page-header-wrapper {
        padding-top: 265px;

        @include screen-from(md) {
          padding-top: 132px;
        }

        @include screen-from(xl) {
          padding-top: 164px;
        }
      }
    }
  }

  &__footer {
    flex: 0;
  }
}

.page-content-wrapper {
  max-width: $layout-content-width-max;
  margin: 0 auto;
  padding-left: $layout-content-padding-x * 0.5;
  padding-right: $layout-content-padding-x * 0.5;

  @include screen-from(md) {
    padding-left: 46px;
    padding-right: 46px;
  }

  @include screen-from(lg) {
    padding-left: $layout-content-padding-x;
    padding-right: $layout-content-padding-x;
  }
}

.featured-articles {
  background-color: $light-gray;
  padding: 36px 20px 21px;
  margin-bottom: 40px;
  max-width: 1360px;

  @include screen-from(md) {
    padding: 60px 26px 36px;
    margin: 0 20px 40px;
    border-radius: 15px;
  }

  @include screen-from(lg) {
    padding: 40px 90px 62px;
    margin: 0 auto 40px;
  }
}

.english-articles {
  background-color: $light-gray;
  padding: 36px 20px 21px;
  margin-bottom: 40px;
  max-width: 1360px;

  @include screen-from(md) {
    padding: 36px 26px;
    margin: 20px 20px 40px;
    border-radius: 15px;
  }

  @include screen-from(lg) {
    padding: 40px 90px;
    margin: 0 auto 40px;
  }

  &__link {
    color: $dark-grey;
    font-weight: $font-weight-medium;
  }
}

.page-header-wrapper {
  padding-top: $navigation-height;
}
