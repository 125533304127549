@import '../base/variables';

.logo {
  display: flex;
  align-items: center;

  &__symbol {
    fill: $beige;

    &_white {
      fill: $white;
    }

    &_black {
      fill: #2e2e33;
    }

    &_orange {
      fill: $light-orange;
    }
  }

  &__caption {
    margin-left: 11px;
    fill: #2e2e33;
    transition: fill 0.3s ease-in-out;

    &_white {
      fill: $white;
    }
  }

  &__by-macpaw {
    margin-left: 10px;
    fill: $heather;
  }
}
