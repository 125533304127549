@import '../base/variables';
@import '../base/mixins';

.jumbotron {
  position: relative;
  padding-left: $layout-content-padding-x * 0.25;
  padding-right: $layout-content-padding-x * 0.25;

  @include screen-from(md) {
    padding-left: $layout-content-padding-x * 0.5;
    padding-right: $layout-content-padding-x * 0.5;
  }

  @include screen-from(lg) {
    padding-left: $layout-content-padding-x;
    padding-right: $layout-content-padding-x;
  }

  &__content {
    border-radius: 20px;
    overflow: hidden;
  }

  &__content_overflow-visible {
    overflow: visible;
  }

  &_grey {
    .jumbotron__content {
      background-color: $smoke-gray;
    }
  }

  &_dusk {
    .jumbotron__content {
      background-color: $dusk-two;
    }
  }

  &_light-dusk {
    .jumbotron__content {
      background-color: $dusk;
    }
  }

  &_plum {
    .jumbotron__content {
      background-color: $plum;
    }
  }

  &_light-grey {
    .jumbotron__content {
      background-color: $light-gray;
    }
  }

  &_pale {
    .jumbotron__content {
      background-color: $pale;
    }
  }

  &_steel {
    .jumbotron__content {
      background-color: $steel;
    }
  }

  &_cloudy-blue {
    .jumbotron__content {
      background-color: $cloudy-blue;
    }
  }

  &_dark-grey-blue {
    .jumbotron__content {
      background-color: $dark-grey-blue;
    }
  }

  &_white {
    .jumbotron__content {
      background-color: $white;
    }
  }

  &_dirty-purple {
    .jumbotron__content {
      background-color: $dirty-purple;
    }
  }

  &_beige-light {
    .jumbotron__content {
      background-color: $beige-light;
    }
  }

  &_very-light-pink {
    .jumbotron__content {
      background-color: $very-light-pink;
    }
  }

  &_light-blue {
    .jumbotron__content {
      background-color: $light-light-blue;
    }
  }

  &_light-light-green {
    .jumbotron__content {
      background-color: $light-light-green;
    }
  }

  &_light-light-violet {
    .jumbotron__content {
      background-color: $light-light-violet;
    }
  }

  &_dark-grey {
    .jumbotron__content {
      background-color: $dark-grey;
    }
  }

  &__container {
    position: relative;
    max-width: $layout-content-width-max;
    margin-left: auto;
    margin-right: auto;
    padding: 20px $layout-content-padding-x * 0.5;

    @include screen-from(lg) {
      padding-left: $layout-content-padding-x;
      padding-right: $layout-content-padding-x;
    }

    &_right, &_left {
      padding-left: $layout-content-padding-x * 0.5;
      padding-right: $layout-content-padding-x * 0.5;

      @include screen-from(lg) {
        max-width: ($layout-content-width-max * 0.5) - 10px;
      }
    }

    &_left {
      @include screen-from(lg) {
        padding-left: $layout-content-padding-x;
        padding-right: 0;
        margin-right: 0;
        margin-left: auto;
      }
    }

    &_right {
      @include screen-from(lg) {
        padding-right: $layout-content-padding-x;
        padding-left: 0;
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
}
