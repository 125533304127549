@import '../../base/variables';

.store-logo {
  display: flex;
  align-items: center;

  &__logo {
    fill: $beige;

    &_white {
      fill: $white;
    }

    &_black {
      fill: #2e2e33;
    }

    &_orange {
      fill: $light-orange;
    }
  }

  &__text {
    margin-left: 12px;
    color: $dark-grey;
    transition: color 0.3s ease-in-out;

    &_dark-mode {
      color: $white;
    }

    &_only-logo {
      display: none;
    }
  }

  &__by-macpaw {
    margin-left: 10px;
    fill: $heather;
  }
}
