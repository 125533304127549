@import '../../base/variables';
@import '../../base/mixins';

.lang-switcher {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;

  &_white {
    color: $white;

    .lang-switcher__arrow {
      fill: $white;
    }
  }

  &_small {
    .lang-switcher__lang {
      font-size: $font-size-text-xs;
      font-weight: $font-weight-medium;
    }
  }

  &__select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }

  &__view {
    position: relative;
    display: block;
    font-size: 0;
    cursor: pointer;
    white-space: nowrap;
  }

  &__flag, &__lang {
    display: inline-block;
    vertical-align: middle;
    pointer-events: none;
  }

  &__flag {
    margin-right: 10px;
  }

  &__flag_icon-only {
    margin: 4px;
  }

  &__lang {
    margin-right: 12px;
    font-size: $font-size-text-md;
  }

  &__arrow {
    width: 10px;
    height: 10px;
    vertical-align: middle;
    fill: $black;
    transition: fill 0.3s ease-in-out;
  }
}
