@import '../base/variables';

.tabs-control {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 25px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: rgba($white, 0.4);
  }

  &_inverse {
    &::after {
      background-color: $heather;
    }
  }

  &__item {
    width: 100%;

    @include screen-from(sm) {
      width: initial;
      margin: 0;
    }

    & + & {
      margin-top: 12px;

      @include screen-from(sm) {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
}
