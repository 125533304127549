@import '../../base/mixins';
@import '../../base/variables';

.emergency-message {
  padding: 28px 20px;
  display: flex;
  background-color: $pale;

  @include screen-from(xl) {
    padding: 36px 40px;
  }

  &-text {
    padding-right: 24px;
  }

  &-icon {
    margin-top: 4px;
    margin-right: 12px;
  }

  &-btn {
    cursor: pointer;
    border: 0;
    margin-left: auto;
    padding: 0;
    min-width: 32px;
    min-height: 32px;
    background: url(image-url('/components/buttons/button-close.svg')) no-repeat center;
    background-size: 32px;
  }
}
