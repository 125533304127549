@import '../../base/variables';
@import '../../base/mixins';

.store-email-subscription-base {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 133px;

  &__input-container {
    display: block;
    min-height: 72px;
    flex: 1 1 100%;
    max-width: 330px;

    @include screen-from(xl) {
      min-height: 74px;
    }
  }

  &__policy-container {
    min-height: 61px;

    @include screen-from(md) {
      flex: 1 1 100%;
    }
  }

  &__help-container {
    display: block;
    min-height: 18px;
    flex: 1 1 100%;

    @include screen-from(xl) {
      height: auto;
    }
  }

  &__help-msg.form-text {
    font-size: $font-size-app-big;
    padding-top: 2px;
  }

  &__checkbox-container {
    display: flex;
    fill: $dark-grey;
  }

  &__policy-description {
    color: $white;
    font-size: $font-size-app-big;
    line-height: 19px;
  }

  &__checkbox {
    opacity: 0;
    display: none;

    &-invalid ~ .store-email-subscription-base__checkbox-label {
      border-color: $lightish-red;
    }

    &:checked ~ .store-email-subscription-base__checkbox-label {
      display: flex;
      align-items: center;
      border-color: $dark-grey;

      > .store-email-subscription-base__checkbox-check {
        display: block;
      }
    }
  }

  &__checkbox-check {
    display: none;
  }

  &__checkbox-label {
    margin-right: 12px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: $white;
    border: 2px solid $heather;
    border-radius: 3px;
  }

  &__show.form-text {
    display: block;
    margin-bottom: 16px;
    padding-left: 0;
  }

  &__help-checkbox-msg.form-text {
    padding-top: 8px;
    margin-bottom: 0;
  }

  &__hide.js-policy-container {
    display: none;
  }

  &__form-button.btn_sm {
    height: 52px;
    padding: 5px 21px;

    &[disabled] {
      opacity: 1;
    }
  }

  &__form-control.form-control {
    height: 52px;
    padding-left: 15px;
    box-shadow: inset 0 0 0 1px $grey-4;

    &.form-group__form-control_invalid {
      box-shadow: inset 0 0 0 1px $lightish-red;

      &:focus {
        box-shadow: inset 0 0 0 1px $lightish-red;
      }
    }

    &.form-group__form-control_valid {
      box-shadow: inset 0 0 0 1px $lightish-green;

      &:focus {
        box-shadow: inset 0 0 0 1px $lightish-green;
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $grey-4;
    }
  }
}
