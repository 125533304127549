@import '../base/variables';

.link {
  color: $dusty-orange;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.stretched-link {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0);
  }
}
