@import '../../base/variables';
@import '../../base/mixins';

.store-lang-switcher {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;

  &_white {
    color: $white;

    .store-lang-switcher__arrow {
      fill: $white;
    }
  }

  &_small {
    .store-lang-switcher__lang {
      font-size: $font-size-text-xs;
      font-weight: $font-weight-medium;
    }
  }

  &__select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }

  &__view {
    position: relative;
    display: block;
    font-size: 0;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
  }

  &__flag, &__lang {
    display: inline-block;
    vertical-align: middle;
    pointer-events: none;
  }

  &__flag {
    margin-right: 8px;

    &_dark {
      filter: invert(0.85);
    }
  }

  &__flag_icon-only {
    margin: 4px;
  }

  &__arrow {
    width: 10px;
    height: 10px;
    vertical-align: middle;
    fill: $black;
    transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;

    &_open {
      transform: rotate(180deg);
    }
  }

  &__lang {
    margin-right: 12px;
    font-size: $font-size-text-md;

    &_dark {
      color: $dark-grey;
    }

    &-container {
      display: none;
      position: absolute;
      width: max-content;
      z-index: 150;
      padding: 8px;
      border-radius: 6px;
      border: 1px solid $light-silver;
      background-color: $white;
      box-shadow: 0 1px 6px 0 $light-silver;
      vertical-align: middle;

      &_open {
        display: block;
      }
    }

    &-item {
      display: block;
      list-style-type: none;
      border-radius: 6px;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: $light-silver;
      }
    }

    &-link {
      display: block;
      color: $dark-grey;
      font-size: $font-size-text-md;
      font-weight: 500;
      line-height: $line-height-app-title;
      text-decoration: none;
      padding: 4px 20px 4px 8px;
    }

    &-marker {
      display: inline-block;
      width: $font-size-text-md;
      height: $font-size-text-md;
      margin-right: 4px;
    }

    &-check {
      display: block;
      margin-top: 2px;
    }
  }
}
