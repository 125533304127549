@use 'sass:math';

$font-family-fallback: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
$font-family-base: 'Avenir Next', $font-family-fallback;

/** Color palette **/
$denim: #384c75;
$dark-grey-blue: #2b464f;
$dirty-purple: #765070;
$charm: #cf6b87;
$dusk: #5a5a7b;
$dusk-two: #443c55;
$steel: #71719a;
$plum: #3a3845;
$purple: #65496c;
$beige: #e6c3a5;
$beige-light: #f7e7db;
$very-light-beige: #f8f2ed;
$beige-tan: #f6cf98;
$white: #fff;
$white-smoke: #f2f2f2;
$light-gray: #f8f8f4;
$smoke-gray: #f5f5f5;
$pale-gray: #f1f1f2;
$pale: #f4f0e4;
$silver: #dedee0;
$light-silver: #e5e5e5;
$heather: #9d9ca2;
$grey-4: #77767a;
$dark-grey: #26262b;
$charcoal-grey: #36383e;
$charcoal-grey-2: #2b2d32;
$charcoal-grey-4: #3a3844;
$charcoal-grey-6: #1a1a1d;
$cloudy-blue: #b7d5ca;
$light-orange: #eaa55e;
$lightish-red: #ff2c47;
$lightish-green: #479a94;
$lightish-blue: #678aa5;
$light-green: #8dada8;
$light-light-green: #dbebea;
$light-light-blue: #dfe9ff;
$light-light-violet: #e3e3eb;
$light-pink: #fadbe6;
$light-yellow: #ffe8c6;
$very-light-pink: #f4efec;
$muted-pink: #cc7b92;
$pale-grey-light: #ebebec;
$very-light-brown: #d9ae89;
$very-light-pink: #f4efeb;
$dusty-orange: #e6842e;
$silver-two: #b9ceca;
$pale-brown: #b88662;
$peach: #edb28a;
$dark-peach: #d38d5c;
$dark-navy: #1b202d;
$slate: #475e65;
$vista-blue: #6a8b7c;
$blue-grey: #6f9ba9;
$navy-blue: #3a5385;
$egg-shell: #f4ebcf;

/** Color palette for Droga landings **/
$pink-light: #ffdedd;
$blue-violet: #652cec;
$blue-violet-2: #603fb0;

$blue-facebook: #3b5998;
$blue-facebook-lighter: #4d69a3;
$blue-facebook-darker: #2d4578;

$red-google: #dd4b39;
$red-google-lighter: #ee5846;
$red-google-darker: #c44232;

$notifications-bg-success: #e4f0ef;
$notifications-bg-error: #ffe0e4;

/** Text colors **/
$text-color-base: $dark-grey;
$text-color-dark: $dusk;
$text-color-gray: $heather;
$text-color-light: $beige-light;
$text-color-inverse: $white;

$headings-color: $dark-grey;
$hr-border-color: $light-silver;

$font-size-h1-hero: 96px;
$font-size-h1-hero-tablet: 64px;
$font-size-h1-hero-mobile: 46px;
$font-size-h1: 46px;
$font-size-h1-mobile: 38px;
$font-size-h2: 40px;
$font-size-h2-mobile: 34px;
$font-size-h3: 36px;
$font-size-h3-mobile: 30px;
$font-size-h4: 26px;
$font-size-h5: 20px;
$font-size-h6: 16px;

$font-size-text-lead: 26px;

$font-size-text-xs: 12px;
$font-size-text-sm: 14px;
$font-size-text-md: 16px;
$font-size-text-lg: 18px;

$font-weight-hero-heading: 700;
$font-weight-heading: 600;
$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$line-height-base: 1.78;
$line-height-small: 1.4289;
$line-height-medium: 1.375;

$paragraph-margin-bottom: 24px;
$headings-margin-bottom: 16px;

/** Layout **/
$layout-width-min: 320px;
$layout-content-width-max: 1260px;
$layout-navigation-width-max: 1440px;
$layout-content-padding-x: 40px;

$footer-bg-color: $charcoal-grey-2;
$store-footer-bg-color: $charcoal-grey-6;

/** Navigation **/
$navigation-font-size: 20px;
$navigation-line-height: 1.84;
$navigation-font-size-tablet: 26px;
$navigation-font-size-desktop: 14px;
$navigation-padding-y: 16px;
$navigation-list-padding-y: 2px;
$navigation-link-padding-y: 5px;
$navigation-link-padding-x: 16px;
$navigation-link-padding-x-tablet: 24px;
$navigation-link-padding-x-desktop: 0;
$navigation-height: floor($navigation-font-size-desktop * $navigation-line-height)
  + ($navigation-padding-y + $navigation-list-padding-y + $navigation-link-padding-y) * 2;

/** Header Banner **/
$header-banner-height-sm: 120px;
$header-banner-height-md: 72px;
$header-banner-offset-sm: 21px;
$header-banner-offset-md: 11px;
$header-banner-offset-lg: 5px;

/** Buttons **/
$btn-padding-x: 30px;
$btn-padding-y: 12px;

/** Utils **/
$images-path: '../../../../../public/static/main/images' !default;

// ##### Bootstrap config ######

/** Grid **/
$grid-gutter-width: 20px;

// Must be in rem because of Bootstrap internal calculations
$font-size-base: math.div(math.div($font-size-text-lg, 1px), 16) * 1rem;
$font-size-small: math.div(math.div($font-size-text-sm, 1px), 16) * 1rem;

/** Desktop-app variables **/

//Base values
$layout-app-article-content-width: 720px;
$line-height-app-base: 1.43;
$line-height-app-title: 1.54;
$font-size-app-h1: 26px;
$font-size-app-h2: 20px;
$font-size-app-subtitle: 26px;
$font-size-app-big: 14px;
$font-size-app-medium: 13px;
$font-size-app-small: 12px;
$app-icon-size: 80px;
$base-radius: 20px;
$rounded-app-icon-radius: 28%;
//Colors
$base-text-color: rgba(0, 0, 0, 0.75);
$base-text-color-reverse: rgba(255, 255, 255, 0.75);
$base-app-text-color: #000;
$base-app-text-color-reverse: $white;
$base-background-color: $white;
$base-background-color-reverse: #1f1f1f;
$block-background-color: rgba(0, 0, 0, 0.05);
$block-background-color-reverse: rgba(255, 255, 255, 0.05);
$base-button-background-color: rgba(0, 0, 0, 0.1);
$base-button-background-color-reverse: rgba(255, 255, 255, 0.2);
$app-article-link-color: #007aff;
