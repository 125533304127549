@import '../base/mixins';
@import '../base/variables';

.modal {
  display: none;

  &_light {
    .modal__overlay {
      background: $light-gray;
    }
  }

  &_side-content {
    .modal__container {
      border: none;
    }
  }

  &_popup {
    .modal {
      &__overlay {
        background-color: rgba($black, 0.4);
      }

      &__container {
        width: 100%;
        min-width: initial;
        max-width: 800px;
        min-height: initial;
        max-height: initial;
      }

      &__close {
        opacity: 0.1;

        &:hover,
        &:focus {
          opacity: 0.2;
        }

        &:active {
          opacity: 0.2;
        }
      }
    }
  }

  &.is-open {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    padding: 10px;
    display: flex;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 200;
    -webkit-overflow-scrolling: touch;

    @include screen-from(lg) {
      padding: 20px;
    }
  }

  &__container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background: $white;
    border-radius: 20px;
    border: 1px solid $light-silver;
  }

  &__content {
    width: 100%;
  }

  &__close {
    z-index: 100;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0;
    width: 32px;
    height: 32px;
    background: url(image-url('/components/buttons/button-close.svg')) no-repeat center;
    background-size: contain;

    &_hidden {
      display: none;
    }

    &:hover,
    &:focus {
      filter: brightness(1.08);
    }

    &:active {
      filter: brightness(0.92);
    }

    @include screen-from(lg) {
      width: 40px;
      height: 40px;
    }
  }
}
