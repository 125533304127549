@import '../base/variables';

$input-vertical-padding: 8px;
$input-horizontal-padding: 12px;

.form-text {
  padding-left: $input-horizontal-padding;
  padding-top: $input-vertical-padding;
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  padding: $input-vertical-padding $input-horizontal-padding;
  font-size: $font-size-text-md;
  font-family: $font-family-base;
  line-height: 2.14;
  background-clip: padding-box;
  border-radius: 6px;
  border: none;
  transition: box-shadow 0.15s ease-in-out;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_light {
    box-shadow: inset 0 0 0 2px $heather;

    &::placeholder {
      color: $heather;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $dark-grey;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &_dark {
    color: $white;
    background-color: #404547;

    &::placeholder {
      color: $heather;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $white;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.form-group {
  margin-bottom: 16px;

  &__invalid-msg {
    display: none;
    color: $lightish-red;
    padding-left: 0;
  }

  &__valid-msg {
    display: none;
    color: $lightish-green;
  }

  &__form-control {
    &_invalid:not([type='checkbox'], .g-recaptcha) {
      box-shadow: inset 0 0 0 2px $lightish-red;

      &:focus {
        box-shadow: inset 0 0 0 2px $lightish-red, 0 0 3px 2px rgba($lightish-red, 0.5);
      }

      &::placeholder {
        color: $lightish-red;
      }
    }

    &_valid {
      box-shadow: inset 0 0 0 2px $lightish-green;

      &:focus {
        box-shadow: inset 0 0 0 2px $lightish-green, 0 0 3px 2px rgba($lightish-green, 0.5);
      }
    }

    &_invalid ~ .form-group__invalid-msg,
    &_valid ~ .form-group__valid-msg {
      display: block;
    }
  }
}

.form-check,
.form-radio {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;

  &__form-control {
    margin-right: 8px;
  }

  &__label {
    font-size: 15px;
    line-height: normal;
  }
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;

  &__form-control {
    position: relative; // For focus state's z-index
    flex: 1 1 auto;
    // Add width 1% and flex-basis auto to ensure that button will not wrap out
    // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
    width: 1%;
    margin-bottom: 0;
  }

  &_append {
    .input-group__form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group__btn {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: -2px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 2;
    }
  }
}
