/* stylelint-disable selector-max-id */
@import '../../base/variables';
@import '../../base/mixins';

.referral-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  min-width: 142px;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }

  &__icon {
    margin-right: 6px;
  }
}
