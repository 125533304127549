@import '../base/mixins';
@import '../base/variables';
@import '../components/logo';

.store-footer {
  &__container {
    padding-top: 32px;
    background: $store-footer-bg-color;

    @include screen-from(md) {
      padding-top: 44px;
    }

    @include screen-from(xl) {
      padding-top: 72px;
    }

    &_light-mode {
      padding-top: 60px;
    }
  }

  &__secondary {
    width: 100%;
    margin-right: 3px;
    margin-bottom: 21px;

    @include screen-from(md) {
      width: auto;
      margin-bottom: 0;
      order: 1;
    }

    @include screen-from(xl) {
      margin: -2px 3px 2px 0;
    }
  }

  &__credits {
    order: 2;

    @include screen-from(md) {
      order: 0;
    }
  }

  &__terms {
    &-container {
      margin-right: auto;
      margin-bottom: 7px;
      order: 1;

      @include screen-from(md) {
        margin-bottom: 0;
      }
    }

    &-link {
      font-size: $font-size-app-small;
      color: $white;
      text-decoration: none;
      margin-right: 26.5px;

      &_gray {
        color: $heather;
      }

      @include screen-from(md) {
        margin-bottom: 0;
      }

      &_credits {
        vertical-align: middle;
        margin-left: 4px;
        margin-bottom: 4px;

        &_light-mode {
          filter: invert(0.9);
        }
      }
    }
  }

  &__logo {
    max-width: 20.5px;

    @include screen-from(md) {
      max-width: 25.4px;
    }

    @include screen-from(xl) {
      max-width: 30.3px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    margin: 0 20px 39.5px;
    justify-content: space-between;

    @include screen-from(md) {
      margin-bottom: 11px;
    }

    @include screen-from(xl) {
      max-width: $layout-navigation-width-max;
      flex-direction: row;
      margin: 0 auto 64px;
      padding: 0 40px;
    }
  }

  &__menu-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;

    @include screen-from(md) {
      margin-top: 34px;
    }

    @include screen-from(xl) {
      width: 744px;
      margin-top: 0;
      gap: 5px;

      &.row {
        flex-wrap: nowrap;
      }
    }
  }

  &__form {
    width: 100%;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 4px;

    @include screen-from(md) {
      flex-direction: row;
      padding-right: 0;
      align-items: center;
    }

    @include screen-from(xl) {
      max-width: 330px;
      padding-right: 0;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__menu {
    &-list {
      list-style-type: none;
      margin: 0;
    }

    &-item {
      line-height: 1.5;
    }

    &-item-link {
      display: inline-block;
      font-size: 16px;
      color: $white;
      text-decoration: none;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__subscription-description {
    margin-top: 30px;
    margin-bottom: 14px;
    font-size: 16px;
    color: $white;

    @include screen-from(md) {
      margin-top: 50px;
      margin-bottom: 13px;
    }

    @include screen-from(xl) {
      margin-top: -1px;
      margin-bottom: 12px;
    }
  }

  &__social-links {
    @include screen-from(md) {
      padding-top: 10px;
    }

    @include screen-from(xl) {
      padding-top: 0;
      padding-bottom: 8px;
    }
  }

  &_light-mode {
    background: $white;
    color: $dark-grey;
  }

  &__sub {
    margin: 0 20px;
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: $font-size-app-small;
    border-top: 1px solid $light-silver;
    color: $heather;

    @include screen-from(md) {
      padding: 18px 0;
    }

    @include screen-from(xl) {
      padding: 19px 0;
    }

    &-container {
      @include screen-from(xl) {
        padding: 0 20px;
        margin: 0 auto;
        max-width: $layout-navigation-width-max;
      }
    }

    &_second-line {
      order: 1;
      flex: 1 1 100%;
      margin-bottom: 22px;
      line-height: 1.7;

      @include screen-from(md) {
        margin-bottom: 0;
        flex: 0.5 1 70%;
      }

      @include screen-from(xl) {
        flex: 1 1 100%;
      }
    }
  }
}
