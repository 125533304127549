@import '../../base/variables';
@import '../../base/mixins';

.store-sidebar {
  &__btn {
    background: transparent;
    user-select: none;
    cursor: pointer;
    border: 0;
    padding: 12px;

    &.store-navigation__menu-link {
      display: flex;

      @include screen-from(xl) {
        display: none;
      }
    }
  }

  &__icon {
    vertical-align: sub;
    transform: scale(1.5);
  }

  &__container {
    padding: 12px;
    display: flex;
    border: 0;
    background-color: transparent;

    &:hover {
      box-shadow: none;
    }
  }

  &__logo {
    max-width: 21px;

    @include screen-from(md) {
      max-width: 38px;
      margin-left: 19px;
      margin-top: 126px;
      align-self: flex-end;
    }

    &-container {
      display: flex;
      width: 100%;
      padding: 6px 2px 12px 0;
      margin-bottom: 28px;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__close {
    background: transparent;
    border: none;
    cursor: pointer;
    user-select: none;
    position: absolute;
    padding: 12px;
    top: 18px;
    right: 12px;
  }

  &__menu {
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: center;
    color: $dark-grey;
    background-color: $white;
    padding: 0 20px 60px;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: 100;
    animation: sidebarMenuSlideDown 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: scroll;

    &_active {
      display: flex;
      animation: sidebarMenuSlide 0.3s ease-in-out;
    }

    @keyframes sidebarMenuSlide {
      from {
        opacity: 0;
        transform: translateX(250%);
      }

      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes sidebarMenuSlideDown {
      from {
        display: flex;
        opacity: 1;
        transform: translateX(0);
      }

      to {
        display: none;
        opacity: 0;
        transform: translateX(250%);
      }
    }

    @include screen-from(md) {
      left: auto;
      width: 456px;
      padding: 0 60px 20px;
    }

    &-separator {
      height: 2px;
      width: 90%;
      background-color: $light-silver;
      margin-bottom: 12px;
    }

    &-link {
      width: 100%;
      display: block;
      padding: 10px 16px;
      border-radius: 6px;
      font-size: $navigation-font-size;
      text-align: left;
      line-height: 32px;
      text-decoration: none;
      font-weight: $font-weight-heading;
      color: inherit;
      transition: background-color 0.3s ease-in-out;
      margin: 0 20px 12px;

      &:hover {
        background-color: $smoke-gray;
      }

      @include screen-from(md) {
        padding: 10px 16px 10px 20px;
        font-size: $font-size-h4;
        line-height: $line-height-medium;
      }
    }
  }

  &__menu-background {
    display: none;
    position: absolute;
    background-color: rgba(38, 38, 43, 0.3);
    height: 150vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    animation: backgroundHide 0.3s ease-in-out;

    @include screen-from(md) {
      &_active {
        display: block;
        animation: backgroundShow 0.3s ease-in-out;
      }

      @keyframes backgroundShow {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      @keyframes backgroundHide {
        from {
          display: block;
          opacity: 1;
        }

        to {
          display: none;
          opacity: 0;
        }
      }
    }
  }
}
