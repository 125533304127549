@import '../base/variables';
@import '../base/mixins';

.tab-select {
  position: relative;
  border-radius: 6px;
  background-color: rgba($white, 0.9);

  &::before {
    content: '';
    position: absolute;
    left: 17px;
    top: 50%;
    height: 12px;
    width: 12px;
    margin-top: -6px;
    transform: rotate(45deg);
    background-color: $charcoal-grey-4;
    transition: background-color 0.3s;
  }

  &::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    background: url(image-url('/components/tab-select/arrow-down.svg')) no-repeat center;
  }

  &__select {
    width: 100%;
    padding: 6px 46px;
    color: $charcoal-grey-4;
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: 1.78;
    appearance: none;
    background: none;
    border: none;
  }
}
