@import '../base/mixins';
@import '../base/variables';
@import '../components/logo';

.site-footer {
  padding: 60px 0 40px;
  background: $footer-bg-color;
  color: $white;

  &-light-mode {
    background: $white;
    color: $dark-grey;
  }

  &__container {
    max-width: $layout-navigation-width-max;
    margin: 0 auto;
    padding-left: $layout-content-padding-x * 0.5;
    padding-right: $layout-content-padding-x * 0.5;

    @include screen-from(lg) {
      padding-left: $layout-content-padding-x;
      padding-right: $layout-content-padding-x;
    }
  }

  &__secondary {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include screen-from(md) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__subscription {
    margin-top: 78px;
    margin-bottom: 125px;
    height: 106px;

    @include screen-from(lg) {
      margin-bottom: 0;
    }
  }

  &__captcha-form-group {
    width: 100%;
    margin-top: 8px;
  }

  &__subscription-description {
    font-size: $font-size-text-sm;

    @include screen-from(md) {
      font-size: $font-size-text-md;
    }
  }

  &__subscription-description-heart {
    position: relative;
    top: 3px;
  }

  &__main {
    padding: 0 0 40px;
  }

  &__menu {
    display: flex;

    &-list {
      list-style-type: none;
      margin: 0;
    }

    &-item {
      &-link {
        font-size: 16px;
        line-height: 2;
        color: $white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__sub {
    border-top: 1px solid #e5e5e5;
    padding-top: 28px;
    font-size: 12px;
  }

  &__terms {
    margin-top: 20px;

    @include screen-from(md) {
      margin-top: 16px;
    }

    &-link {
      font-size: 12px;
      color: #969799;
      text-decoration: none;
      margin-right: 28px;
      margin-bottom: 14px;

      @include screen-from(md) {
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      &_credits {
        vertical-align: middle;
        margin-bottom: 2px;
      }
    }
  }
}
